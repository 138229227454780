import { Logo } from '@seek/logo';
import { useTranslations } from '@vocab/react';
import { ContentBlock, Heading, Stack, TextLink } from 'braid-design-system';
import type React from 'react';

import translations from 'src/locales.vocab';

interface JobAdPreviewHeaderProps {
  children: React.ReactNode;
}

export const JobAdPreviewHeaderLayout = ({
  children,
}: JobAdPreviewHeaderProps) => {
  const { t } = useTranslations(translations);
  return (
    <ContentBlock data={{ testid: 'job-ad-preview-header' }}>
      <Stack space="medium">
        <TextLink
          href="https://talent.seek.com.au"
          target="_blank"
          data={{ testid: 'job-ad-preview-header-seek-logo' }}
        >
          <Logo brand="seek" />
        </TextLink>

        <Heading level="2" data={{ testid: 'job-ad-preview-header-text' }}>
          {t('SEEK Job Ad Preview')}
        </Heading>
        <>{children}</>
      </Stack>
    </ContentBlock>
  );
};

import type { LayoutOverride } from '@seek/branding-assets';
import { JobDetailsPreview } from '@seek/job-details-view';
import { MelwaysProvider } from '@seek/melways-react';
import { useLanguage } from '@vocab/react';
import { Box, ContentBlock } from 'braid-design-system';
import { useEffect, useState } from 'react';

import type { supportedLanguages } from 'src/config';
import type { JobDetailsPreviewItem } from 'src/types/JobAdPreviewItem';

import { mapMetropolisJobAdPreview } from '../../utils/mapMetropolisJobAdPreview';

import * as layoutStyles from './index.css';

interface JobDetailsPreviewProps {
  jobDetailsPreview: JobDetailsPreviewItem;
  layout: LayoutOverride;
}

export const JobDetailsPreviewLayout = ({
  jobDetailsPreview,
  layout,
}: JobDetailsPreviewProps) => {
  const { language } = useLanguage();
  const [show, setShow] = useState(false);

  // This is a hack because I've already wasted too much time on this.
  // For _reasons_ (https://github.com/SEEK-Jobs/metropolis/pull/673), job ad preview renders inside a iframe.
  // This is to allow media queries and block interactions (e.g. link clicking).
  // The iframe content is conditionally shown based on state as well as opacity 0
  // this state is then updated using `onLoad` callback however
  // for some reason `onLoad` isn't called properly. For example if there's no image/branding
  // it just doesn't call `onLoad` which means the opacity is 0.

  // I am guessing this is something to do with SSR and rehydration but even in a base SKU app it did it
  // however it's been a bit of a heisenbug and behaved differently there too.
  useEffect(() => {
    setShow(true);
  }, []);

  const metropolisJobDetailsPreview = mapMetropolisJobAdPreview(
    jobDetailsPreview,
    language as (typeof supportedLanguages)[number],
  );

  if (!metropolisJobDetailsPreview) {
    return <></>;
  }

  const containerClass =
    layout === 'desktop'
      ? layoutStyles.desktopContainer
      : layoutStyles.mobileContainer;

  return (
    <ContentBlock>
      <Box className={containerClass}>
        {/*
         * JobDetailsPreview requires a MelwaysProvider as it uses the same
         * components as the full job details view. The preview doesn't
         * render any clickable links, so MelwaysProvider isn't used in
         * practice, and we're just passing default props for language & site
         * https://myseek.atlassian.net/browse/DCS-9815
         */}
        <MelwaysProvider.Static path="/" language="en" site="candidate-seek-au">
          {/* DO NOT REMOVE (see comment above) */}
          {show ? (
            <JobDetailsPreview
              job={metropolisJobDetailsPreview}
              layout={layout}
              title="preview-iframe"
              uniqueContainerValue="job-details-preview"
            />
          ) : null}
        </MelwaysProvider.Static>
      </Box>
    </ContentBlock>
  );
};

import { useTranslations } from '@vocab/react';
import { Text, Alert } from 'braid-design-system';
import React, { Fragment } from 'react';

import translations from 'src/locales.vocab';

import type { UIMode } from '../../types/UIMode';

interface DescriptionMessageProps {
  uiMode: UIMode;
}
export const DescriptionMessage = ({ uiMode }: DescriptionMessageProps) => {
  const { t } = useTranslations(translations);
  return (
    <Fragment>
      {(uiMode === 'iframe' || uiMode === 'drawer') && (
        <Text data={{ testid: 'iframe-description-message' }} tone="secondary">
          {t(
            'This is a preview of what your job ad will look like to candidates.',
          )}
        </Text>
      )}
      {uiMode === 'document' && (
        <Alert tone="info" data={{ testid: 'document-description-message' }}>
          <Text>
            {t(
              'If you make changes to your ad, open a new preview from the ATS to see the updated version.',
            )}
          </Text>
        </Alert>
      )}
    </Fragment>
  );
};

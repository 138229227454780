import 'braid-design-system/reset';

import { useTranslations } from '@vocab/react';
import { Alert, Card, Text } from 'braid-design-system';
import React, { Fragment } from 'react';

import translations from 'src/locales.vocab';

import type { UIMode } from '../../types/UIMode';
import { JobAdPreviewHeaderLayout } from '../JobAdPreviewHeaderLayout';

interface ErrorProps {
  uiMode?: UIMode;
}

export default ({ uiMode }: ErrorProps) => {
  const { t } = useTranslations(translations);
  return (
    <Fragment>
      <Card data={{ testid: 'error-page' }}>
        <JobAdPreviewHeaderLayout>
          <Alert tone="critical">
            <Text data={{ testid: 'error-message' }}>
              {uiMode === 'iframe' &&
                t(
                  'Something went wrong. Try to preview the job ad again later.',
                )}
              {uiMode === 'drawer' &&
                t(
                  'Something went wrong. Close the preview and try again later.',
                )}
              {uiMode !== 'iframe' &&
                uiMode !== 'drawer' &&
                t(
                  'Something went wrong. Close the browser and preview the job ad again later.',
                )}
            </Text>
          </Alert>
        </JobAdPreviewHeaderLayout>
      </Card>
    </Fragment>
  );
};
